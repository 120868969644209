import { Dialog, DialogContent, DialogDescription, DialogHeader } from "@/components/ui/dialog";
import { useTranslation } from "react-i18next";

interface Props {
    colors: {
        primary: string;
        secondary: string;
    }
    product_name: string;
    open: boolean;
    setOpen: (data: boolean) => void;
}

export default function ThanksDialog({ open, setOpen, colors, product_name }: Props) {
    const { t } = useTranslation()
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent
                style={{
                    background: `linear-gradient(to bottom right, ${colors.primary}, ${colors.secondary})`
                }}
                className="max-w-[500px] w-full rounded-xl sm:rounded-xl p-[2px]"
            >
                <div className="space-y-4 w-full bg-white p-4 rounded-[calc(0.75rem-2px)]">
                    <DialogHeader>
                        <DialogDescription>
                            <p className="text-xl xl:text-2xl font-semibold flex-col text-center">
                                {t('thanks_dialog.message', { product_name })}
                            </p>
                        </DialogDescription>
                    </DialogHeader>
                </div>
            </DialogContent>
        </Dialog >

    )
}