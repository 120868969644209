import LanguageSelector from "@/components/LanguageSelector";

export default function Header({logo_url}: {logo_url: string}) {

    return (
        <header className="sticky top-0 left-0 px-5 h-[4rem] items-center md:px-10 z-50 w-full flex justify-between border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <img loading="lazy" src={logo_url} alt="Logo" className="w-36 h-auto" />
            <LanguageSelector/>
        </header>
    )
}