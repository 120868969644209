import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"
import XHR from "i18next-xhr-backend";

i18next
    .use(XHR)
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        locales: ["pt-BR", "pt", "en-US", "en"],
        compatibilityJSON: 'v3',
        debug: false,
        initImmediate: false,
        fallbackLng: "pt-BR",
        detection: {
            order: ['cookie'],
            caches: ['cookie']
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
            addPath: "/dist/locales/{{lng}}/{{ns}}.json"
        },
        react: {
            useSuspense: true
        },
        returnObjects: true
    })