import { Button } from "@/components/ui/button"
import { Loader2 } from "lucide-react"
import ReactPlayer from "react-player"
import MainPageForm from "./components/MainPageForm"
// import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion"
import { FaFacebook, FaGlobe, FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { useEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import { useParams, useSearchParams } from "react-router-dom"
import api from "@/hooks/useApi"
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ErrorPage from "./components/ErrorMessage";

interface LandingPage {
    uuid: string;
    product: string;
    logo_url: string;
    favicon_url: string;
    colors: {
        primary: string;
        secondary: string;
    };
    content: {
        seo: {
            google_tag: string;
            title: string;
            description: string;
            keywords: string;
            canonical_url: string;
            og: {
                site_name: string;
                image: {
                    url: string;
                    width: string;
                    height: string;
                }
            }

        };
        introduction: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            video_url: string;
            button: string;
        };
        features: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            items: {
                icon: string;
                title: string;
                description: string;
            }[];
            button: string;
        };
        benefits: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            items: {
                icon: string;
                title: string;
                description: string;
            }[];
            button: string;
        };
        benchmarking: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            infographic: string;
            button: string;
        };
        form_section: {
            background_color: string;
            text_color: string;
            title: string;
            subtitle: string;
            form_content: {
                title: string;
                button: string;
            }
        }

    }
}

interface ApiResponse {
    landing_page: LandingPage
    code: number;
    success: boolean;
}

export default function MainPage() {
    const [landingPage, setLandingPage] = useState<LandingPage>()
    const [error, setError] = useState<number>()
    const [loading, setLoading] = useState<boolean>(true)
    const { landing_page_path } = useParams()
    const [searchParams, _] = useSearchParams();
    const { t } = useTranslation()

    const form_ref = useRef<HTMLDivElement>(null)

    const scrollToForm = () => {
        if (form_ref.current) {
            const top = form_ref.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: top - 100, behavior: 'smooth' });
        }
    };

    const fetchLandingPage = async (lang: string) => {
        await api.get<ApiResponse>(`/landing-pages/show?landing_page_path=${landing_page_path}&language=${lang}`)
            .then((success) => {
                setLandingPage(success.data.landing_page)
                document.documentElement.style.setProperty('--primary-color', success.data.landing_page.colors.primary);
                document.documentElement.style.setProperty('--secondary-color', success.data.landing_page.colors.secondary);
                i18n.changeLanguage(lang)
            })
            .catch((error) => {
                setError(error.response.data.code)
                return (
                    <ErrorPage error={error.response.data.code} />
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        i18n.changeLanguage('pt-BR')
        fetchLandingPage(i18n.language || 'pt-BR')
    }, [])

    if (loading) {
        return (
            <div className="min-h-screen w-screen flex justify-center items-center">
                <Loader2 className="animate-spin" />
            </div>
        )
    }

    if (!landingPage || error) {
        return (
            <ErrorPage error={error} />
        )
    }

    return (
        <>
            <Helmet>
                <title>{landingPage.content.seo.title}</title>
                <meta name="description" content={landingPage.content.seo.description} />
                <meta name="keywords" content={landingPage.content.seo.keywords} />
                <meta property="og:title" content={landingPage.content.seo.title} />
                <meta property="og:description" content={landingPage.content.seo.description} />
                <meta property="og:url" content={landingPage.content.seo.canonical_url} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={landingPage.content.seo.og.site_name} />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:image" content={landingPage.content.seo.og.image.url} />
                <meta property="og:image:type" content="image/webp" />
                <meta property="og:image:width" content={landingPage.content.seo.og.image.width} />
                <meta property="og:image:height" content={landingPage.content.seo.og.image.height} />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={landingPage.content.seo.canonical_url} />
                <link rel="icon" href={landingPage.favicon_url} type="image/x-icon"></link>

                <script>
                    {`
                    if (window.location.hostname === 'pages.brainlegacy.tech') {
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${landingPage.content.seo.google_tag}');
                    }
                    `}
                </script>
            </Helmet>
            <Header logo_url={landingPage.logo_url} />
            <main className="overflow-x-hidden flex flex-col items-center min-w-screen justify-start min-h-screen text-center">
                <section
                    style={{
                        backgroundColor: landingPage.content.introduction.background_color,
                        color: landingPage.content.introduction.text_color
                    }}
                    className="px-4 py-5 w-full flex justify-center">
                    <div className="space-y-5 w-full max-w-6xl flex flex-col items-center">
                        <h1
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className={'text-3xl md:text-3xl lg:text-4xl font-bold'}
                        >
                            {landingPage.content.introduction.title}
                        </h1>
                        <h2 className="md:text-lg lg:text-xl">
                            {landingPage.content.introduction.subtitle}
                        </h2>
                        <div className="w-full max-w-3xl aspect-video">
                            <ReactPlayer
                                className="aspect-video"
                                url={landingPage.content.introduction.video_url}
                                width="100%"
                                height="100%"
                                controls
                                preload="auto"
                            />
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToForm}
                        >
                            {landingPage.content.introduction.button}
                        </Button>
                    </div>
                </section>

                <section
                    style={{
                        backgroundColor: landingPage.content.features.background_color,
                        color: landingPage.content.features.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="flex flex-col justify-center items-center gap-5 px-4 py-8 w-full max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.features.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl mb-10">
                            {landingPage.content.features.subtitle}
                        </h3>
                        <div className="flex justify-center pb-5">
                            <ul className="flex flex-col gap-3 md:grid md:gap-10 md:grid-cols-2 md:items-start md:justify-start">
                                {landingPage.content.features.items.map((item, index) => (
                                    <li key={index} className="flex items-start gap-2 text-start max-w-sm">
                                        <img src={item.icon} className="w-[2rem]" alt="ícone de funcionalidades" />
                                        <p>
                                            <span className="font-medium text-lg">
                                                {item.title}:
                                            </span>
                                            <span>
                                                {" " + item.description}
                                            </span>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToForm}
                        >
                            {landingPage.content.features.button}
                        </Button>
                    </div>
                </section>
                <section
                    style={{
                        backgroundColor: landingPage.content.benefits.background_color,
                        color: landingPage.content.benefits.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="px-4 flex flex-col justify-center items-center gap-5 py-8 w-full max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.benefits.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl mb-10">
                            {landingPage.content.benefits.subtitle}
                        </h3>
                        <div className="flex justify-center pb-5">
                            <ul className="flex flex-col gap-3 md:grid md:gap-16 md:grid-cols-2 md:items-start md:justify-start">
                                {landingPage.content.benefits.items.map((item, index) => (
                                    <li key={index} className="flex items-start gap-2 text-start max-w-sm break-words">
                                        <img src={item.icon} className="w-[5rem]" alt="ícone de benefício" />
                                        <p>
                                            <span className="font-medium text-lg">
                                                {item.title}:
                                            </span>
                                            <span>
                                                {" " + item.description}
                                            </span>
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToForm}
                        >
                            {landingPage.content.benefits.button}
                        </Button>
                    </div>
                </section>
                <section
                    style={{
                        backgroundColor: landingPage.content.benchmarking.background_color,
                        color: landingPage.content.benchmarking.text_color
                    }}
                    className="w-full flex flex-col items-center">
                    <div className="px-4 space-y-4 py-8 max-w-6xl">
                        <h2
                            style={{
                                color: landingPage.colors.secondary
                            }}
                            className="text-3xl md:text-3xl lg:text-4xl font-bold">
                            {landingPage.content.benchmarking.title}
                        </h2>
                        <h3 className="md:text-lg lg:text-xl">
                            {landingPage.content.benchmarking.subtitle}
                        </h3>
                        <img src={landingPage.content.benchmarking.infographic} alt={t('alts.infographic')} />
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                            onClick={scrollToForm}
                        >
                            {landingPage.content.benchmarking.button}
                        </Button>
                    </div>
                </section>
                <section
                    style={{
                        backgroundColor: landingPage.content.form_section.background_color,
                        color: landingPage.content.form_section.text_color
                    }}
                    className="py-8 px-4 space-y-4 flex flex-col items-center w-full">
                    <h2
                        style={{
                            color: landingPage.colors.secondary
                        }}
                        className="text-3xl md:text-3xl lg:text-4xl font-bold">
                        {landingPage.content.form_section.title}
                    </h2>
                    <h3 className="md:text-lg lg:text-xl">
                        {landingPage.content.form_section.subtitle}
                    </h3>
                    <div className="w-full flex justify-center" ref={form_ref}>
                        <MainPageForm language={i18n.language} colors={landingPage.colors} landing_page_uuid={landingPage.uuid} indicator_uuid={searchParams.get('iu')} product_name={landingPage.product} form_text={landingPage.content.form_section.form_content} />
                    </div>
                </section>
                {/* <section className="py-8 px-4 space-y-4 max-w-6xl">
                    <p className="text-3xl md:text-3xl lg:text-4xl font-bold">
                        Perguntas frequentes
                    </p>
                    <Separator />
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                    <Accordion className=" bg-[#0a0972] text-start text-secondary px-4 rounded-xl" type="single" collapsible>
                        <AccordionItem value="item-1" className="max-w-6xl">
                            <AccordionTrigger>Como a maratona pode me ajudar a transformar uma escola comum em uma escola de excelência?</AccordionTrigger>
                            <AccordionContent className="max-w-2xl ">
                                Começa com a consciência e as práticas das Dicas de Ouro, as quais são as primeiras coisas que devem funcionar na escola na busca da excelência.
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>

                    <Button
                        className="py-6 w-full max-w-[18rem] bg-gradient-to-r from-[#76419B] to-[#4E195E] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300">
                        Conhecer mais!
                    </Button>
                </section> */}
                <section className="py-8 px-4 gap-5 flex flex-col items-center justify-center">
                    <p className="text-3xl md:text-3xl lg:text-4xl font-bold">
                        {t('contact.title')}
                    </p>
                    <div>
                        <p className="text-lg">{t('contact.description')}</p>
                        <p className="text-lg">
                            {t('contact.light_description')}
                        </p>
                    </div>
                    <div className="space-y-2">
                        <div className="flex gap-3 justify-center text-3xl">
                            <a href="https://www.facebook.com/profile.php?id=100084199213436" target="_blank" aria-label="Ir para o Facebook da BrainLegacy Technology">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/brainlegacytech" target="_blank" aria-label="Ir para o Instagram da BrainLegacy Technology">
                                <RiInstagramFill />
                            </a>
                            <a href="https://www.brainlegacy.tech" target="_blank" aria-label="Ir para o site da BrainLegacy Technology">
                                <FaGlobe />
                            </a>
                            <a href="https://www.linkedin.com/company/brainlegacytech" target="_blank" aria-label="Ir para o LinkedIn da BrainLegacy Technology">
                                <FaLinkedin />
                            </a>
                        </div>
                    </div>
                </section>

                <section className="py-8 px-4 space-y-4 max-w-6xl">
                    <p className="text-3xl lg:text-4xl font-bold">
                        {t('company.title', { landingPage })}
                    </p>
                    <p className="text-lg">
                        {t('company.light_description')}
                    </p>
                    <a href="https://www.brainlegacy.tech" target="_blank" rel="noreferrer">
                        <Button
                            style={{
                                background: `linear-gradient(to right, ${landingPage.colors.primary}, ${landingPage.colors.secondary})`
                            }}
                            className="mt-4 py-6 w-full max-w-[18rem] font-semibold text-xl rounded-xl hover:filter hover:brightness-90 transition duration-300"
                        >
                            {t('company.more_button')}
                        </Button>
                    </a>
                </section>


            </main >
            <Footer logo_url="https://landingpage.ms.brainlegacy.tech/storage/blt_logo.webp" />
        </>
    )
}