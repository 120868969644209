import { useTranslation } from "react-i18next";
import { useState } from "react";
import 'flag-icon-css/css/flag-icons.min.css'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

import { ChevronDown } from 'lucide-react';

export default function LanguageSelector() {
    const { i18n } = useTranslation()
    const [isOpen, setIsOpen] = useState(false);

    const languages = [
        { code: "en-US", lang: "English", flag: "us", countryName: 'United States' },
        { code: "pt-BR", lang: "Português", flag: "br", countryName: "Brasil" }
    ]

    function changeLanguage(language: string) {
        i18n.changeLanguage(language)
        window.location.reload();
    }

    return (

        <>
            <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                <DropdownMenuTrigger className="flex items-center gap-2 rounded-lg px-3 py-2 border bg-background">
                    <span className={`flag-icon flag-icon-${languages.find(lang => lang.code === i18n.language)?.flag || "pt"}`}></span>
                    {languages.find(lang => lang.code === i18n.language)?.lang}
                    <ChevronDown/>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {languages.map(language => (
                        <DropdownMenuItem
                            key={language.code}
                            className="px-5 py-2 cursor-pointer hover:bg-gray-700 flex gap-3"
                            onClick={() => {
                                changeLanguage(language.code);
                                setIsOpen(false);
                            }}
                        >
                            <span className={`flag-icon flag-icon-${language.flag}`}></span>
                            {language.lang}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
}