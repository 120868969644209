import Helmet from "react-helmet"
import { useTranslation } from "react-i18next";

interface ErrorPageProps {
    error?: number;
}

export default function ErrorPage({error}: ErrorPageProps) {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <title>{error == 404 ? "Landing page não encontrada" : "Erro inesperado"}</title>
            </Helmet>
            <div className="min-h-screen w-screen p-4 flex items-center justify-center text-xl xl:text-2xl font-semibold flex-col text-center">
                {error === 404 ?
                    t('error.404')
                    :
                    t('error.500')
                }
                <p className="font-light text-lg xl:text-xl">
                    {t('error.support')}
                </p>
            </div>
        </>
    )
}