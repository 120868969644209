import { useTranslation } from "react-i18next"
import { IoMdMail } from "react-icons/io";
import { FaFacebook, FaGlobe, FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

export default function Footer({logo_url}: {logo_url: string}) {

    const {t} = useTranslation()

    return (
        <footer className="flex flex-col gap-4 p-8 bg-black text-white text-start w-full">
            <div className="flex flex-col gap-4 lg:gap-14 lg:flex-row">
                <div className="space-y-4 lg:w-1/2">
                    <img loading="lazy" className="w-48 h-auto" src={logo_url} alt={t('alts.blt_logo')}/>
                    <p className="font-extralight text-sm">
                        {t('footer.mission')}
                    </p>
                </div>
                <div className="flex flex-col gap-5 justify-between items-start lg:w-1/2 py-5 lg:py-0">
                    <div>
                        <p className="font-semibold">
                            {t('footer.company_title')}
                        </p>
                        <div className="font-extralight text-sm flex flex-col gap-2 lg:gap-10 xl:gap-5 lg:flex-row">
                            <div className="flex flex-col gap-2 xl:gap-5 xl:flex-row">
                                <a href="https://www.brainlegacy.tech" target="_blank" rel="noreferrer" className="flex gap-1 items-center text-lg hover:underline">
                                    <FaGlobe />
                                    {t('footer.site')}
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=100084199213436" target="_blank" rel="noreferrer" className="flex gap-1 items-center text-lg hover:underline">
                                    <FaFacebook />
                                    {t('footer.facebook')}
                                </a>
                            </div>
                            <div className="flex flex-col gap-2 xl:gap-5 xl:flex-row">
                                <a href="https://www.instagram.com/brainlegacytech" target="_blank" rel="noreferrer" className="flex gap-1 items-center text-lg hover:underline">
                                    <RiInstagramFill />
                                    {t('footer.instagram')}
                                </a>
                                <a href="https://www.linkedin.com/company/brainlegacytech" target="_blank" rel="noreferrer" className="flex gap-1 items-center text-lg hover:underline">
                                    <FaLinkedin />
                                    {t('footer.linkedin')}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="font-semibold">
                            {t('footer.contact_title')}
                        </p>
                        <div className="font-extralight text-sm flex flex-col gap-2 lg:gap-10 xl:gap-2 lg:flex-row">
                            <p className="flex gap-1 items-center text-lg">
                                <IoMdMail />
                                {t('footer.support_email')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className="text-xs">{t('footer.rights')}</p>
            </div>
        </footer>
    )
}