import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./pages";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "./pages/components/ErrorMessage";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ErrorPage error={404} />} />
        <Route path="/:landing_page_path" element={<MainPage />} />
      </Routes>
      <ToastContainer/>
    </BrowserRouter>
  )
}

export default App
